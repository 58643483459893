import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createProductFail,
  createProductStart,
  createProductSuccess,
  deleteProductFail,
  deleteProductStart,
  deleteProductSuccess,
  fetchProductFail,
  fetchProductListFail,
  fetchProductListStart,
  fetchProductListSuccess,
  fetchProductStart,
  fetchProductSuccess,
  fetchPublicPopularProductsSuccess,
  fetchPublicPopularProductFail,
  fetchPublicPopularProductsStart,
  updateProductFail,
  updateProductStart,
  updateProductSuccess,
  fetchPublicProductsStart,
  fetchPublicProductsSuccess,
  fetchPublicProductsFail,
  fetchPublicProductStart,
  fetchPublicProductSuccess,
  fetchPublicProductFail,
  initiateProductsFetchStart,
  initiateProductsFetchSuccess,
  initiateProductsFetchFail,
  fetchUserProductListStart,
  fetchUserProductListSuccess,
  fetchUserProductListFail,
  fetchAllUserProductsCountStart,
  fetchAllUserProductsCountSuccess,
  fetchAllUserProductsCountFail,
  updateProductFilesStart,
  updateProductFilesSuccess,
  updateProductFilesFail,
  publishProductStart,
  publishProductFail,
  publishProductSuccess,
  depublishProductStart,
  depublishProductSuccess,
  depublishProductFail,
  setPublicProductListParams,
  fetchRecommendedProductsFail,
  fetchRecommendedProductsStart,
  fetchRecommendedProductsSuccess,
  fetchUserLikedProductListStart,
  fetchUserLikedProductListSuccess,
  fetchUserLikedProductListFail,
  fetchPublicUserCreationsListStart,
  fetchPublicUserCreationsListSuccess,
  fetchPublicUserCreationsListFail,
  fetchVerifiedPurchaseProductListFail,
  fetchVerifiedPurchaseProductListStart,
  fetchVerifiedPurchaseProductListSuccess,
  updateStudioIoFileFail,
  updateStudioIoFileStart,
  updateStudioIoFileSuccess,
  updateInstructionsFileFail,
  updateInstructionsFileStart,
  updateInstructionsFileSuccess,
  updateLdrFileSuccess,
  updateLdrFileStart,
  updateLdrFileFail,
  updatePartsFileStart,
  updatePartsFileSuccess,
  updatePartsFileFail,
  fetchSetsSuggestionsStart,
  fetchSetsSuggestionsSuccess,
  fetchSetsSuggestionsFail,
  fetchSelfAddedProductsListStart,
  fetchSelfAddedProductsListSuccess,
  fetchSelfAddedProductsListFail,
  downloadAllAssetsSuccess,
  downloadAllAssetsStart,
  downloadAllAssetsFail,
  fetchSetProductsStart,
  fetchSetProductsSuccess,
  fetchSetProductsFail,
  updateAdditionalInstructionsFileStart,
  updateAdditionalInstructionsFileSuccess,
  updateAdditionalInstructionsFileFail,
  fetchSetOptionsStart,
  fetchSetOptionsFail,
  fetchSetOptionsSuccess,
  fetchSetProductsForSaleStart,
  fetchSetProductsForSaleSuccess,
  fetchSetProductsForSaleFail,
  setAdminProductListParams,
  fetchUserSetOptionsStart,
  fetchUserSetOptionsSuccess,
  fetchUserSetOptionsFail,
  fetchUserAltListStart,
  fetchUserAltListSuccess,
  fetchUserAltListFail,
  fetchPublicBundlesStart,
  fetchPublicBundlesSuccess,
  fetchPublicBundlesFail,
  fetchPublicBundleSectionsStart,
  fetchPublicBundleSectionsSuccess,
  fetchPublicBundleSectionsFail,
  fetchUserPurchasedProductsStart,
  fetchUserPurchasedProductsSuccess,
  fetchUserPurchasedProductsFail,
  fetchAllSetsStart,
  fetchAllSetsSuccess,
  fetchAllSetsFail,
  fetchBundleSetFail,
  fetchBundleSetStart,
  fetchBundleSetSuccess,
  fetchExploreSetsFail,
  fetchExploreSetsStart,
  fetchExploreSetsSuccess,
  toggleUserLikedProductFail,
  toggleUserLikedProductStart,
  toggleUserLikedProductSuccess,
  fetchUserLikedProductsFail,
  fetchUserLikedProductsStart,
  fetchUserLikedProductsSuccess,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getFormData } from '../../utility/form/formData';
import {
  Product,
  ProductStatus,
  ProductTier,
  ProductType,
} from '../../domain/Product';
import { TablePriorityUpdate } from '../../common/Table/Table';
import download from 'downloadjs';

const API_URL = '/products';

export enum PublicProductFilterTypes {
  // eslint-disable-next-line no-unused-vars
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
  // eslint-disable-next-line no-unused-vars
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  // eslint-disable-next-line no-unused-vars
  NEWEST_FIRST = 'NEWEST_FIRST',
  // eslint-disable-next-line no-unused-vars
  OLDEST_FIRST = 'OLDEST_FIRST',
  // TODO: add logic
  // // eslint-disable-next-line no-unused-vars
  // MOST_COMMENTED = 'MOST_COMMENTED',
  // // eslint-disable-next-line no-unused-vars
  // TOP_SELLERS = 'TOP_SELLERS',
}

export enum MyCollectionFilterTypes {
  // eslint-disable-next-line no-unused-vars
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
  // eslint-disable-next-line no-unused-vars
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  // eslint-disable-next-line no-unused-vars
  NEWEST_FIRST = 'NEWEST_FIRST',
  // eslint-disable-next-line no-unused-vars
  OLDEST_FIRST = 'OLDEST_FIRST',
  // TODO: add logic
  // eslint-disable-next-line no-unused-vars
  // MOST_COMMENTED = 'MOST_COMMENTED',
  // eslint-disable-next-line no-unused-vars
  // TOP_SELLERS = 'TOP_SELLERS',
}

export type DraftProductCreateRequest = {
  title: string;
  description: string;
  specification: string;
  bricks: number;
  numberOfParts: number;
  legoSetNumber: string;
  creationTime: number;
  categoryId: number;
  themeId: number;
  type: ProductType;
};

export type ProductDraftUpdateRequest = {
  id: string;
  title: string;
  description: string;
  specification: string;
  bricks: number;
  numberOfParts: number;
  legoSetNumber: string;
  creationTime: number;
  categoryId: number;
  themeId: number;
  type: ProductType;
};

export type ProductCreateRequest = {
  title: string;
  description: string;
  specification: string;
  bricks: number;
  numberOfParts: number;
  legoSetNumber: string;
  creationTime: number;
  categoryId: number;
  type: ProductType;
  tier?: ProductTier;
  images?: File[];
  priorities: TablePriorityUpdate[];
};

export type ProductUpdateRequest = {
  id: string;
  title: string;
  description: string;
  specification: string;
  bricks: number;
  numberOfParts: number;
  legoSetNumber: string;
  creationTime: number;
  categoryId: number;
  type: ProductType;
  tier: ProductTier;
  images: File[];
  existingImages: number[];
  priorities: TablePriorityUpdate[];
};

export type ProductAdminFilterParams = ListParams & {
  types?: string[];
  bigBuy?: boolean;
  statuses?: string[];
};

export type UpdateProductFilesRequest = {
  id: string;
  existingImages: number[];
  priorities: TablePriorityUpdate[];
  images: File[];
};

export type UpdatePartsFileRequest = {
  id: string;
  parts: File;
  existingPartsId?: number;
};

export type UpdateStudioIoFileRequest = {
  id: string;
  studioIo: File;
  existingStudioIo?: number;
};

export type UpdateInstructionsFileRequest = {
  id: string;
  instructions: File;
  existingInstructionsId?: number;
};

export type UpdateAdditionalInstructionsFileRequest = {
  id: string;
  additionalInstructions: File;
  existingAdditionalInstructionsId?: number | null;
};

export type UpdateLdrFileRequest = {
  id: string;
  existingLdrId?: number;
  ldr: File;
};

export type ProductFilterParams = ListParams & {
  type: string[];
  tier: string[];
  bricks: string[];
  numberOfParts: string[];
  category: string[];
  filterType: PublicProductFilterTypes;
  themes: string[];
  sets: string[];
  isInUserCollection: boolean;
};

export type SetProductFilterParams = ProductFilterParams & {
  type: string[];
};

export type VerifiedPurchaseFilterParams = ListParams & {
  type?: ProductType;
  filterType?: MyCollectionFilterTypes;
};

export type UserProductFilterParams = ListParams & {
  status: ProductStatus;
  isBundleList?: boolean;
};

export type SetsSuggestionsRequest = {
  title?: string;
  legoSetNumber?: string;
};

export type GetRecommendedProductsParams = {
  type: ProductType;
};

export type CreateDraftBundleRequest = {
  title: string;
  description: string;
  specification: string;
  bricks: number;
  categoryId: number;
  setId: string;
  altIds: string[];
  images: File[];
  priorities: TablePriorityUpdate[];
};

export type UpdateBundleRequest = {
  title: string;
  description: string;
  specification: string;
  bricks: number;
  categoryId: number;
  images: File[];
  existingImages: number[];
  priorities: TablePriorityUpdate[];
  altIds: string[];
};

export const fetchProductList =
  (params: ProductAdminFilterParams, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setAdminProductListParams({ ...params }));

    dispatch(fetchProductListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchProductListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchProductListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchUserLikedProductList =
  (username: string, params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchUserLikedProductListStart());
    return axios
      .get(`${API_URL}/public/user/${username}`, { params })
      .then((response) => {
        dispatch(fetchUserLikedProductListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserLikedProductListFail(err?.response?.data?.message));
      });
  };

export const fetchProduct =
  (id: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchProductStart());
    return axios
      .get(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(fetchProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchProductFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const initiateProductsFetch =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(initiateProductsFetchStart());
    return axios
      .get(`${API_URL}/initiate`)
      .then((response) => {
        dispatch(initiateProductsFetchSuccess());
        showToast(
          translate(intl, 'SUCCESS.PRODUCTS_FETCH_INITIATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(initiateProductsFetchFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const createDraftProduct =
  (inputs: DraftProductCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createProductStart());
    return axios
      .post(`${API_URL}/draft`, { ...inputs })
      .then((response) => {
        dispatch(createProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          createProductFail(translate(intl, err?.response?.data?.message)),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateDraftProduct =
  (inputs: ProductDraftUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateProductStart());
    return axios
      .patch(`${API_URL}/draft/${inputs.id}`, { ...inputs })
      .then(() => {
        dispatch(updateProductSuccess());
      })
      .catch((err) => {
        dispatch(
          updateProductFail(translate(intl, err?.response?.data?.message)),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateProductFiles =
  (inputs: UpdateProductFilesRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateProductFilesStart());
    return axios
      .patch(`${API_URL}/files/${inputs.id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateProductFilesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateProductFilesFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updatePartsFile =
  (inputs: UpdatePartsFileRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updatePartsFileStart());
    return axios
      .patch(`${API_URL}/files/parts/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updatePartsFileSuccess());
      })
      .catch((err) => {
        dispatch(updatePartsFileFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateStudioIoFile =
  (inputs: UpdateStudioIoFileRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateStudioIoFileStart());
    return axios
      .patch(`${API_URL}/files/studio-io/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateStudioIoFileSuccess());
      })
      .catch((err) => {
        dispatch(updateStudioIoFileFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateInstructionsFile =
  (inputs: UpdateInstructionsFileRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateInstructionsFileStart());
    return axios
      .patch(`${API_URL}/files/instructions/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateInstructionsFileSuccess());
      })
      .catch((err) => {
        dispatch(updateInstructionsFileFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateAdditionalInstructionsFile =
  (inputs: UpdateAdditionalInstructionsFileRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateAdditionalInstructionsFileStart());
    return axios
      .patch(
        `${API_URL}/files/additional-instructions/${inputs.id}`,
        getFormData(inputs),
      )
      .then(() => {
        dispatch(updateAdditionalInstructionsFileSuccess());
      })
      .catch((err) => {
        dispatch(
          updateAdditionalInstructionsFileFail(err?.response?.data?.message),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateLdrFile =
  (inputs: UpdateLdrFileRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateLdrFileStart());
    return axios
      .patch(`${API_URL}/files/ldr/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateLdrFileSuccess());
      })
      .catch((err) => {
        dispatch(updateLdrFileFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const publishProduct =
  (id: string, intl: IntlShape, withToast: boolean = true) =>
  (dispatch: Dispatch) => {
    dispatch(publishProductStart());
    return axios
      .patch(`${API_URL}/publish/${id}`)
      .then(() => {
        dispatch(publishProductSuccess());

        if (withToast) {
          showToast(translate(intl, 'SUCCESS.PRODUCT_PUBLISH'), 'success');
        }
      })
      .catch((err) => {
        dispatch(publishProductFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const depublishProduct =
  (id: string, intl: IntlShape, withToast: boolean = true) =>
  (dispatch: Dispatch) => {
    dispatch(depublishProductStart());
    return axios
      .patch(`${API_URL}/depublish/${id}`)
      .then(() => {
        dispatch(depublishProductSuccess());

        if (withToast) {
          showToast(translate(intl, 'SUCCESS.PRODUCT_DEPUBLISH'), 'success');
        }
      })
      .catch((err) => {
        dispatch(depublishProductFail(err?.response?.data?.message));
      });
  };

export const createProduct =
  (inputs: ProductCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createProductStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createProductSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.PRODUCT_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          createProductFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };

export const updateProduct =
  (inputs: ProductUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateProductStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateProductSuccess());
        showToast(translate(intl, 'SUCCESS.PRODUCT_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateProductFail(err?.response?.data?.message));
      });
  };

export const fetchPublicPopularProducts = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicPopularProductsStart());
  return axios
    .get(`${API_URL}/public-popular`)
    .then((response) => {
      dispatch(fetchPublicPopularProductsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicPopularProductFail(err?.response?.data?.message));
    });
};

export const fetchExploreSets = () => (dispatch: Dispatch) => {
  dispatch(fetchExploreSetsStart());
  return axios
    .get(`${API_URL}/explore-sets`)
    .then((response) => {
      dispatch(fetchExploreSetsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchExploreSetsFail(err?.response?.data?.message));
    });
};

export const fetchPublicProducts =
  (params: ProductFilterParams, isAuthorized: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchPublicProductsStart());
    setPublicProductListParams(params);

    const endpoint = isAuthorized ? 'authorized-public' : 'public';

    return axios
      .get(`${API_URL}/${endpoint}`, { params })
      .then((response) => {
        dispatch(fetchPublicProductsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicProductsFail(err?.response?.data?.message));
      });
  };

export const fetchPublicProduct = (id: string) => (dispatch: Dispatch) => {
  dispatch(fetchPublicProductStart());
  return axios
    .get(`${API_URL}/public/${id}`)
    .then((response) => {
      dispatch(fetchPublicProductSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicProductFail(err?.response?.data?.message));
    });
};

export const fetchRecommendedProducts =
  (id: string, isAuthorized?: boolean, params?: GetRecommendedProductsParams) =>
  (dispatch: Dispatch) => {
    dispatch(fetchRecommendedProductsStart());
    const endpoint = isAuthorized
      ? 'authorized-recommended'
      : 'public-recommended';

    return axios
      .get(`${API_URL}/${endpoint}/${id}`, { params })
      .then((response) => {
        dispatch(fetchRecommendedProductsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchRecommendedProductsFail(err?.response?.data?.message));
      });
  };

export const deleteProduct =
  (id: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteProductStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteProductSuccess());
        showToast(translate(intl, 'SUCCESS.PRODUCT_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteProductFail(err?.response?.data?.message));
      });
  };

export const fetchUserProductList =
  (params: UserProductFilterParams) => (dispatch: Dispatch) => {
    dispatch(fetchUserProductListStart());
    return axios
      .get(`${API_URL}/user`, { params })
      .then((response) => {
        dispatch(fetchUserProductListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserProductListFail(err?.response?.data?.message));
      });
  };

export const fetchAllUserProductsCount = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUserProductsCountStart());
  return axios
    .get(`${API_URL}/user-total`)
    .then((response) => {
      dispatch(fetchAllUserProductsCountSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUserProductsCountFail(err?.response?.data?.message));
    });
};

export const fetchPublicUserCreationsList =
  (username: string, params: ListParams, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(fetchPublicUserCreationsListStart());
    return axios
      .get(`${API_URL}/public/creations/user/${username}`, { params })
      .then((response) => {
        dispatch(fetchPublicUserCreationsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          fetchPublicUserCreationsListFail(err?.response?.data?.message),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchVerifiedPurchaseProductList =
  (params: VerifiedPurchaseFilterParams) => (dispatch: Dispatch) => {
    dispatch(fetchVerifiedPurchaseProductListStart());
    return axios
      .get(`${API_URL}/user/verified-purchase`, { params })
      .then((response) => {
        dispatch(fetchVerifiedPurchaseProductListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          fetchVerifiedPurchaseProductListFail(err?.response?.data?.message),
        );
      });
  };

export const fetchSetsSuggestions =
  (params: SetsSuggestionsRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchSetsSuggestionsStart());
    return axios
      .get(`${API_URL}/sets-suggestions`, {
        params,
      })
      .then((response) => {
        dispatch(fetchSetsSuggestionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSetsSuggestionsFail(err?.response?.data?.error));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchSelfAddedProductsList =
  (params: VerifiedPurchaseFilterParams) => (dispatch: Dispatch) => {
    dispatch(fetchSelfAddedProductsListStart());
    return axios
      .get(`${API_URL}/user/self-added`, { params })
      .then((response) => {
        dispatch(fetchSelfAddedProductsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSelfAddedProductsListFail(err?.response?.data?.message));
      });
  };

export const onDownloadProductAssets =
  (id: string, zipName: string) => (dispatch: Dispatch) => {
    dispatch(downloadAllAssetsStart(id));

    return axios
      .post(`${API_URL}/download-product-assets/${id}`, null, {
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(downloadAllAssetsSuccess(id));

        download(
          new Blob([response.data], {
            type: 'application/zip',
          }),
          zipName,
          'application/zip',
        );
      })
      .catch((err) => {
        dispatch(downloadAllAssetsFail(err?.response?.data?.error));
      });
  };

export const fetchPublicSetProducts =
  (params: ProductFilterParams, isAuthorized: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchSetProductsStart());

    const endpoint = isAuthorized ? 'authorized-public' : 'public';

    return axios
      .get(`${API_URL}/${endpoint}/sets`, { params })
      .then((response) => {
        dispatch(fetchSetProductsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSetProductsFail(err?.response?.data?.message));
      });
  };

export const fetchSetProductsForSale =
  (params: ProductFilterParams, isAuthorized: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchSetProductsForSaleStart());

    const endpoint = isAuthorized ? 'authorized-public' : 'public';

    return axios
      .get(`${API_URL}/${endpoint}/sets-products-sale`, { params })
      .then((response) => {
        dispatch(fetchSetProductsForSaleSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSetProductsForSaleFail(err?.response?.data?.message));
      });
  };

export const fetchPublicBundles =
  (params: ProductFilterParams, isAuthorized: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchPublicBundlesStart());

    const endpoint = isAuthorized ? 'authorized-public' : 'public';

    return axios
      .get(`${API_URL}/${endpoint}/bundles`, { params })
      .then((response) => {
        dispatch(fetchPublicBundlesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicBundlesFail(err?.response?.data?.message));
      });
  };

export const fetchPublicBundleSections =
  (params: ProductFilterParams, isAuthorized: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(fetchPublicBundleSectionsStart());

    const endpoint = isAuthorized ? 'authorized-public' : 'public';

    return axios
      .get(`${API_URL}/${endpoint}/bundle-sections`, { params })
      .then((response) => {
        dispatch(fetchPublicBundleSectionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicBundleSectionsFail(err?.response?.data?.message));
      });
  };

export const fetchSetsOptions =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchSetOptionsStart());
    return axios
      .get(`${API_URL}/set-options`, { params })
      .then((response) => {
        dispatch(fetchSetOptionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSetOptionsFail(err?.response?.data?.message));
      });
  };

export const fetchUserSetOptions =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchUserSetOptionsStart());
    return axios
      .get(`${API_URL}/user/set-options`, { params })
      .then((response) => {
        dispatch(fetchUserSetOptionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserSetOptionsFail(err?.response?.data?.message));
      });
  };

export const fetchUserAltList =
  (setId: string, params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchUserAltListStart());
    return axios
      .get(`${API_URL}/${setId}/set-alts`, { params })
      .then((response) => {
        dispatch(fetchUserAltListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserAltListFail(err?.response?.data?.message));
      });
  };

export const fetchAdminBundleAltList =
  (bundleId: string, params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchUserAltListStart());
    return axios
      .get(`${API_URL}/${bundleId}/admin/set-alts`, { params })
      .then((response) => {
        dispatch(fetchUserAltListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserAltListFail(err?.response?.data?.message));
      });
  };

export const createDraftBundle =
  (inputs: CreateDraftBundleRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createProductStart());
    return axios
      .post(`${API_URL}/bundle/draft`, getFormData(inputs))
      .then((response) => {
        dispatch(createProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          createProductFail(translate(intl, err?.response?.data?.message)),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateBundle =
  (id: string, inputs: UpdateBundleRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateProductStart());
    return axios
      .patch(`${API_URL}/bundle/${id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateProductSuccess());
        showToast(translate(intl, 'SUCCESS.UPDATE_BUNDLE'), 'success');
      })
      .catch((err) => {
        dispatch(
          updateProductFail(translate(intl, err?.response?.data?.message)),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchUserPurchasedProducts =
  (orderId: number) => (dispatch: Dispatch) => {
    dispatch(fetchUserPurchasedProductsStart());
    return axios
      .get(`${API_URL}/order-product/${orderId}`)
      .then((response) => {
        dispatch(fetchUserPurchasedProductsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserPurchasedProductsFail(err?.response?.data?.message));
      });
  };

export const fetchAllSets = () => (dispatch: Dispatch) => {
  dispatch(fetchAllSetsStart());
  return axios
    .get(`${API_URL}/all-sets`)
    .then((response) => {
      dispatch(fetchAllSetsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllSetsFail(err?.response?.data?.message));
    });
};

export const fetchBundleSetProduct =
  (legoSetNumber: string) => (dispatch: Dispatch) => {
    dispatch(fetchBundleSetStart());
    return axios
      .get(`${API_URL}/set/${legoSetNumber}`)
      .then((response) => {
        dispatch(fetchBundleSetSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchBundleSetFail(err?.response?.data?.message));
      });
  };

export const toggleUserLikedProduct =
  (product: Product) => (dispatch: Dispatch) => {
    dispatch(toggleUserLikedProductStart());
    return axios
      .patch(`${API_URL}/user/${product.id}`)
      .then((response) => {
        dispatch(toggleUserLikedProductSuccess(response.data));
      })
      .catch((err) => {
        dispatch(toggleUserLikedProductFail(err?.response?.data?.message));
      });
  };

export const fetchUserLikedProducts = () => (dispatch: Dispatch) => {
  dispatch(fetchUserLikedProductsStart());
  return axios
    .get(`${API_URL}/user-liked-products`)
    .then((response) => {
      dispatch(fetchUserLikedProductsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserLikedProductsFail(err?.response?.data?.message));
    });
};
